import React, { Component } from 'react';
//Maybe redundant. Got this from here https://medium.com/front-end-hacking/simplified-google-maps-api-in-a-react-app-46981441d2c9
//But we're calling this from MapContainer.js too, so maybe remove that one.
import { GoogleApiWrapper } from 'google-maps-react'
import MapContainer from './MapContainer'

class Home extends Component {
  render() {
    return (
      <div>
        {/*<h1>Welcome to Damian Files</h1>*/}
        <MapContainer google={this.props.google} />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDS38LashNP_KQ2S4cMsewvUIpcWOE6GEw',
})(Home)
