
import React, { Component } from 'react';
import ReactDOM from 'react-dom'

//import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

export default class MapContainer extends Component {
  constructor(props) {
    super(props);
    //const api = 'http://127.0.0.1:8080/api/v1.0/locations';
    const api = 'https://api.damianfiles.com/api/v1.0/locations';
    this.state = {
      locations: [],
      api: api,
      showingInfoWindow: false,
      picturesPage: '/pictures/?'
    };
    
  }
  
  componentDidMount() {
    console.log('entered DidMount')
    fetch(this.state.api)
      .then(results => results.json())
      .then(data => {
        this.setState({ locations: data.locations });
        this.loadMap();
      });
  }

  loadMap() {
    
    if (this.props && this.props.google) {
      const {google} = this.props;
      const maps = google.maps;
      const mapRef = this.refs.map;
      const node = ReactDOM.findDOMNode(mapRef);
      const picturesPage = this.state.picturesPage;
      
    
      const mapConfig = Object.assign({}, {
        center: {lat: 0, lng: 0},
        zoom: 3,
        mapTypeId: 'hybrid', // optional main map layer. Terrain, satellite, hybrid or roadmap--if unspecified, defaults to roadmap.

      });
      this.map = new maps.Map(node, mapConfig);
      
      const infowindow = new google.maps.InfoWindow();

      this.state.locations.forEach( location => { // iterate through locations saved in state
        const marker = new google.maps.Marker({ // creates a new Google maps Marker object.
          position: {lat: location.latitude, lng: location.longitude}, // sets position of marker to specified location
          map: this.map, // sets markers to appear on the map we just created on line 35
          title: location.location, // the title of the marker is set to the name of the location
        });
        marker.addListener('click', function() {
          infowindow.setContent(
            `<strong>Trip: <a href="${picturesPage + "category=" + location.category}">${location.category}</a></strong><br />
            <strong>Country: <a href="${picturesPage + "country=" + location.country}">${location.country}</a></strong><br />
            <strong>Location: <a href="${picturesPage + "location=" + location.location}"">${location.location}</a></strong>`);
          infowindow.open(this.map, marker);
        });
      })

      this.map.addListener('click', function() {
      });

    }
  }

  render() {
    const style = { // MUST specify dimensions of the Google map or it will not work. Also works best when style is specified inside the render function and created as an object
      width: '90vw', // 90vw basically means take up 90% of the width screen. px also works.
      height: '80vh', // 75vh similarly will take up roughly 75% of the height of the screen. px also works.
      margin: '0 auto'
    }
    //console.log(this.state.locations);
    return ( // in our return function you must return a div with ref='map' and style.

      <div ref="map" style={style}>
        loading map...
      </div>
    )
  }
}
