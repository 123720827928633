import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
//import { Route } from 'react-router-dom'



class Pictures extends Component {
  constructor(props) {
    super(props);
    this.openOrigImage = this.openOrigImage.bind(this);
    this.updateOrigImage = this.updateOrigImage.bind(this);
    this.getThumbnailIndex = this.getThumbnailIndex.bind(this);
    //const api = 'http://127.0.0.1:8080/api/v1.0/pictures/?';
    const api = 'https://api.damianfiles.com/api/v1.0/pictures/?';
    const query = new URLSearchParams(props.location.search);
    const location = query.get('location');
    const country = query.get('country');
    const category = query.get('category');
    let param = '';
    let paramValue = '';
    let equal = '=';
    if (location && location.length > 0) {
      param = 'location';
      paramValue = location;
    } else if (country && country.length > 0) {
      param = 'country';
      paramValue = country;
    } else if (category && category.length > 0) {
      param = 'category';
      paramValue = category;
    } else {
      equal = '';
    }
    this.state = {
      pictures: [],
      api: api,
      param: param,
      paramValue: paramValue,
      equal: equal,
      selectedPictureSrc: ''
    };
  }

  componentDidMount() {
    
    fetch(this.state.api + this.state.param + this.state.equal + this.state.paramValue)
      .then(results => results.json())
      .then(data => this.setState({ pictures: data.pictures }));

  }

  getThumbnailIndex(index) {
    //console.log(index);
    //console.log('here');
    var pictures = this.state.pictures.slice();
    var img = pictures[index];
    this.setState({ selectedPictureSrc: img.orig });
    //console.log(index);
  }

  openOrigImage(index) {
    //var pictures = this.state.pictures.slice();
    //var img = pictures[index];
    //this.setState({ selectedPictureSrc: img.orig });
    console.log(index);
    //Open in a new window
    //window.open(this.state.selectedPictureSrc);
    //Open in the same window. Maybe there's a better way of doing this?
    window.location.href = this.state.selectedPictureSrc;

  }

  updateOrigImage(index) {
    //var pictures = this.state.pictures.slice();
    //var img = pictures[index];
    //this.setState({ selectedPictureSrc: img.orig });
    console.log('updateOrigImage');
    //Open in a new window
    //window.open(this.state.selectedPictureSrc);
    //Open in the same window. Maybe there's a better way of doing this?
    window.location.href = this.state.selectedPictureSrc;

  }

  render() {

    return (
      <div className="gallery-container">
        <Gallery
          images={this.state.pictures}
          lightboxWillOpen={this.getThumbnailIndex}
          currentImageWillChange={this.updateOrigImage}
          onClickImage={this.openOrigImage}
          backdropClosesModal={true}
          enableImageSelection={false}
        />
      </div>
    );
  }
}

export default Pictures
