import React from 'react'
import { Link } from 'react-router-dom'

// The Header creates links that can be used to navigate
// between routes.
const Header = () => (
  <header className="App-header">
    <h1 className="App-title"><Link className="App-title-link" to='/'>Damian Files</Link></h1>
    {/*
    <nav>
      <h4 className="App-menu-item"><Link to='/'>Home</Link></h4>
      <h4 className="App-menu-item"><Link to='/pictures'>Pictures</Link></h4>
    </nav>
    */}
    <hr />
  </header>
)

export default Header
