import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Pictures from './Pictures'
//require('@google-cloud/debug-agent').start({serviceContext: {enableCanary: false}});

const Main = () => (

  <main className="main-div">
    <Switch>
      <Route exact path='/' component={Home}/>
      <Route path='/pictures' component={Pictures}/>
    </Switch>
  </main>
)

export default Main
